<template>
  <div class="filter">
    <div class="filter-nav" :style="{ zIndex: 9999 }">
      <a @click="onOpen('type')" class="filter-item" href="javascript:;">
        <span>工单状态</span>
        <img src="./icon_down_gray@2x.png" alt="" />
      </a>
      <a @click="onOpen('time')" class="filter-item" href="javascript:;">
        <input class="input_cell" placeholder="关键字查询" v-model.trim="keyWord" />
      </a>
      <div class="filter-search" @click="onChange">
        <img src="./icon_news_search@2x.png" alt="" />
      </div>
    </div>
    <!-- 类型选择 -->
    <mt-popup v-model="typeVisible" position="top">
      <div class="typelist">
        <div class="typelist-divider"></div>
        <div v-for="(item, index) in typeList" :key="index" class="typelist-card">
          <label v-if="item.label">{{ item.label }}</label>
          <ul>
            <li
              @click="onSelectType(item2)"
              :class="[typeValue === item2.value && 'active', item2.empty && 'empty']"
              v-for="(item2, index2) in item.items"
              :key="index2"
            >
              {{ item2.title }}
            </li>
          </ul>
        </div>
      </div>
    </mt-popup>
    <!-- 时间选择 -->
  </div>
</template>
<script>
import { listDictData } from '@/api/property'

export default {
  components: {},
  data() {
    return {
      typeVisible: false,
      typeValue: 0,
      typeList: [
        {
          label: '',
          items: [{ title: '全部类型', value: 0 }]
        },
        {
          label: '工单状态',
          items: []
        }
      ],
      keyWord: ''
    }
  },
  mounted() {
    this.loadConfig()
  },
  methods: {
    async loadConfig() {
      const resList = await listDictData({ code: 'repair_status' })
      const items = resList.map(({ title, value }) => {
        return {
          title: title,
          value: value
        }
      })
      const num = items.length % 4
      if (num !== 0) {
        for (let i = 0; i < 4 - num; i++) {
          items.push({ empty: true })
        }
      }
      this.typeList[1].items = items
    },
    onSelectType(item) {
      console.log(item)
      this.typeValue = item.value
      this.onChange()
    },
    onChange() {
      const up = {}
      if (this.typeValue !== 0) {
        up['repairStatus'] = this.typeValue
      }
      if (this.keyWord) {
        up['keyWord'] = this.keyWord
      }
      this.$emit('change', up)
      this.onClose()
    },
    onOpen(name) {
      if (name === 'type') {
        this.typeVisible = true
      } else {
        this.typeVisible = false
      }
    },
    onClose() {
      this.typeVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.filter {
  height: 44px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;

  &-nav {
    padding-right: 60px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    background: rgba(255, 255, 255, 1);
  }

  &-item {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    position: relative;

    &::after {
      content: '';
      width: 1px;
      height: 16px;
      background: rgba(240, 240, 240, 1);
      right: 0;
      position: absolute;
    }

    &:nth-child(2) {
      &::after {
        display: none;
      }
    }

    img {
      width: 9px;
      height: 6px;
      margin-left: 6px;
    }

    span {
      font-size: 15px;
    }

    .input_cell {
      width: 140px;
      font-size: 13px;
      background: none;
      border: none;
    }
  }

  &-search {
    position: absolute;
    height: 44px;
    width: 60px;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.1);
    border: none;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .typelist {
    width: 100vw;
    background-color: white;
    transform: translateY(44px);
    padding-bottom: 20px;

    &-divider {
      height: 7px;
      background-color: #f5f5f5;
    }

    &-card {
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      label {
        font-size: 12px;
        color: #666666;
        margin-bottom: 10px;
      }

      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0;
        margin-top: -12px;

        li {
          width: 23%;
          height: 37px;
          background: rgba(245, 245, 245, 1);
          border-radius: 2px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          margin-top: 12px;

          &.active {
            background: rgba(231, 243, 254, 1);
            color: #178cf9;
          }

          &.empty {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }

  .time-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 10px;
    border-bottom: 1px solid #e5e5e5;

    &__btn {
      height: 35px;
      width: 120px;

      input {
        height: 35px;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        border: none;
        outline: none;
        text-align: center;
        font-size: 14px;
      }
    }

    &__divider {
      height: 1px;
      width: 20px;
      background-color: #e5e5e5;
      margin: 0 5px;
    }
  }

  .confirm-btn {
    background-color: #178cf9;
  }
}

.infotDetai {
  .search {
    height: 36px;
    overflow: hidden;
    line-height: 36px;
    padding-left: 18.5px;
    background: #f3f3f3;
    border-radius: 30px;
    vertical-align: middle;

    img {
      vertical-align: middle;
      padding-right: 0.5px;
    }

    input {
      border: none;
      width: 235px;
      background: #f3f3f3;
      vertical-align: middle;
      font-size: 15px;
      color: #333;
    }

    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button {
      display: none;
    }
  }
}
</style>
